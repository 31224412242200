import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
{
  path : '',
  loadChildren: () => import('./initial/initial.module').then(m => m.InitialModule)
  //loadChildren: () => import('./featured-project/featured-project-routing.module').then(m => m.FeaturedProjectRoutingModule)
},
{
  path: '**',
  redirectTo: ''
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
